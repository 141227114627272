import { api } from '@/api/axios-base';
import { getItem } from '@/api/api-methods';

export const fetchGroupSucursals = async ({
  numero_sucursal = null,
  nombre_sucursal = null,
  direccion_sucursal = null,
  institutionId = null,
  systemStatus = null,
  limit = null,
  offset = 0,
}) => {
  try {
    const institutionFilter = institutionId
      ? `institucion_educativa=${institutionId}`
      : '';
    const numberSucursalFilter = numero_sucursal
      ? `&numero_sucursal=${numero_sucursal}`
      : '';
    const nameSucursalFilter =
      nombre_sucursal !== null ? `&nombre_sucursal=${nombre_sucursal}` : '';
    const addressSucursalFilter =
      direccion_sucursal !== null
        ? `&direccion_sucursal=${direccion_sucursal}`
        : '';
    const systemStatusFilter =
      systemStatus !== null ? `&estatus_sistema=${systemStatus}` : '';
    const limitFilter = limit !== null ? `&limit=${limit}` : '';
    const offsetFilter = offset !== 0 ? `&offset=${offset}` : '';
    const response = await getItem(
      '/app-personas/filters/sucursal?'.concat(
        institutionFilter,
        numberSucursalFilter,
        nameSucursalFilter,
        addressSucursalFilter,
        systemStatusFilter,
        limitFilter,
        offsetFilter
      )
    );
    const { results, count, e } = response;
    if (e) {
      return {
        ok: false,
        message: 'No se pudieron obtener los egresos por categoria. ' + e,
        count: 0,
      };
    }
    // console.log("Estatus", results);
    return { ok: true, data: results, count: count };
  } catch (error) {
    console.error('Error al obtener los egresos por categoria. ', error);
    return {
      ok: false,
      message: 'Error al intentar obtener los egresos por categoria. ' + error,
      count: 0,
    };
  }
};
export const getCriteriaById = async (idsubject) => {
  try {
    // Fetch the criteria data
    const response = await getItem(
      `/app-personas/criterio-evaluacion/${idsubject}`
    );

    // Return the value directly from the resolved promise
    return response || 'Sin datos personales';
  } catch (error) {
    // Log the error for debugging purposes
    console.error('Error al intentar obtener criterio de evaluación:', error);

    // Return a default message in case of an error
    return 'Sin datos personales';
  }
};
export const postImage = async (formData) => {
  try {
    const response = await api.post('/app-personas/imagen', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    const responseData = await response.data;
    return responseData;
  } catch (error) {
    return { ok: false, message: error?.toString() };
  }
};

export const fetchGroups = async ({
  nombre_grupo = null,
  sucursal = null,
  salon_clases = null,
  profesores = null,
  materia_profesor = null,
  alumnos = null,
  ciclo_escolar = null,
  plan_estudios = null,
  nivel_educativo = null,
  institutionId = null,
  systemStatus = null,
  limit = null,
  offset = 0,
}) => {
  try {
    const institutionFilter = institutionId
      ? `institucion_educativa=${institutionId}`
      : '';
    const nameGroupFilter =
      nombre_grupo !== null ? `&nombre_grupo=${nombre_grupo}` : '';
    const branchFilter = sucursal !== null ? `&sucursal=${sucursal}` : '';
    const classRoomFilter =
      salon_clases !== null ? `&salon_clases=${salon_clases}` : '';
    const teacherFilter =
      profesores !== null ? `&profesores=${profesores}` : '';
    const subjectTeacherFilter =
      materia_profesor !== null ? `&materia_profesor=${materia_profesor}` : '';
    const studentsFilter = alumnos !== null ? `&alumnos=${alumnos}` : '';
    const cicleScolarFilter = ciclo_escolar
      ? `&ciclo_escolar=${ciclo_escolar}`
      : '';
    const syllabusFilter =
      plan_estudios !== null ? `&plan_estudios=${plan_estudios}` : '';
    const educationalLevel =
      nivel_educativo !== null ? `&nivel_educativo=${nivel_educativo}` : '';
    const systemStatusFilter =
      systemStatus !== null ? `&estatus_sistema=${systemStatus}` : '';
    const limitFilter = limit !== null ? `&limit=${limit}` : '';
    const offsetFilter = offset !== 0 ? `&offset=${offset}` : '';
    const response = await getItem(
      '/app-personas/filters/grupo?'.concat(
        institutionFilter,
        nameGroupFilter,
        branchFilter,
        classRoomFilter,
        subjectTeacherFilter,
        syllabusFilter,
        educationalLevel,
        teacherFilter,
        studentsFilter,
        cicleScolarFilter,
        systemStatusFilter,
        limitFilter,
        offsetFilter
      )
    );
    const { results, count, e } = response;
    if (e) {
      return {
        ok: false,
        message: 'No se pudieron obtener los egresos por categoria. ' + e,
        count: 0,
      };
    }
    // console.log("Estatus", results);
    return { ok: true, data: results, count: count };
  } catch (error) {
    console.error('Error al obtener los egresos por categoria. ', error);
    return {
      ok: false,
      message: 'Error al intentar obtener los egresos por categoria. ' + error,
      count: 0,
    };
  }
};
export const fetchGroupsByEI = async (institutionId) => {
  try {
    const response = await api.get(
      `/app-personas/filters/grupo?institucion_educativa=${institutionId}&estatus_sistema=true`
    );
    const responseData = await response.data;
    if (response.status === 200) return responseData;
  } catch (error) {
    console.error(
      'Error al intentar obtener categorias de proveedor por institucion educativa',
      error
    );
  }
};
export const fetchGroupsById = async (groupId) => {
  try {
    const response = await api.get(`/app-personas/grupo/${groupId}`);
    const responseData = await response.data;
    if (response.status === 200) return responseData;
  } catch (error) {
    console.error(
      'Error al intentar obtener categorias de proveedor por institucion educativa',
      error
    );
  }
};
export const fetchBranchByEI = async (institutionId) => {
  try {
    const response = await api.get(
      `/app-personas/filters/sucursal?institucion_educativa=${institutionId}&estatus_sistema=true&limit=100`
    );
    const responseData = await response.data;
    if (response.status === 200) return responseData;
  } catch (error) {
    console.error(
      'Error al intentar obtener categorias de proveedor por institucion educativa',
      error
    );
  }
};
export const fetchClassroomsByEI = async (institutionId) => {
  try {
    const response = await api.get(
      `/app-personas/filters/salon-clases?institucion_educativa=${institutionId}&estatus_sistema=true&limit=100`
    );
    const responseData = await response.data;
    if (response.status === 200) return responseData;
  } catch (error) {
    console.error(
      'Error al intentar obtener categorias de proveedor por institucion educativa',
      error
    );
  }
};
export const fetchSchoolCycleByEI = async (institutionId) => {
  try {
    const response = await api.get(
      `/app-personas/filters/ciclo-escolar?institucion_educativa=${institutionId}&estatus_sistema=true&limit=100`
    );
    const responseData = await response.data;
    if (response.status === 200) return responseData;
  } catch (error) {
    console.error(
      'Error al intentar obtener categorias de proveedor por institucion educativa',
      error
    );
  }
};
/*export const assignImageToGroup = async (groupId, imageFile) => {
    try {
        if (this.imageFile !== null) {
            const responseUpload = await uploadFile(imageFile)//this.imageFile
            if (responseUpload.mensaje.includes('Archivo guardado con éxito')) {
                const urlImage = responseUpload.archivos[0].url_archivo
                const responseAddImage = await api.post('/app-personas/helpers/update-imagen-grupo', {
                    url_imagen: urlImage,
                    id_grupo: groupId
                })
                if (responseAddImage.error) {
                    console.error(responseAddImage.error)
                }
            }
        }
    } catch (error) {
        console.error('Ocurrió un error al intentar agregar imagen a grupo', error);
    }
};*/
export const createGroup = async (
  groupImage,
  nameGroup,
  idBranch,
  idClassRoom,
  IdschoolCycle,
  duracion_ciclo_escolar_meses,
  institutionId,
  schoolLevelId = null,
  syllabusId = null
) => {
  let result = null;
  try {
    // console.log("Imagen", groupImage);
    if (groupImage !== undefined && groupImage !== null) {
      const formData = new FormData();
      formData.append('imagen', groupImage);
      formData.append('nombre_imagen', 'Imagen grupo');
      //formData.append('estatus_sistema', true);
      const response = await postImage(formData);
      result = response.id;
      if (!response) return response;
    }
    const response = await api.post('/app-personas/grupo', {
      nombre_grupo: nameGroup, //this.group.nombre_grupo,
      sucursal: idBranch, //this.group.id_sucursal,
      salon_clases: idClassRoom, //this.group.id_salon_clases,
      imagen_grupo: result === undefined || result === null ? null : result,
      ciclo_escolar: IdschoolCycle, //this.group.id_ciclo_escolar,
      duracion_ciclo_escolar_meses: duracion_ciclo_escolar_meses,
      institucion_educativa: institutionId, //this.group.id_institucion_educativa
      nivel_educativo: schoolLevelId ? schoolLevelId : null,
      plan_estudios: syllabusId ? syllabusId : null,
    });
    if (response.error) {
      console.error(response.error);
    } else {
      result = [];
      return response.data;
    }
  } catch (error) {
    console.error('Error al intentar crear el grupo', error);
  }
};
export const updateGroup = async (
  groupId,
  groupImage,
  nameGroup,
  idBranch,
  idClassRoom,
  IdschoolCycle,
  duracion_ciclo_escolar_meses,
  institutionId,
  schoolLevelId = null,
  syllabusId = null
) => {
  let result = null;
  try {
    if (groupImage !== undefined && groupImage !== null) {
      const formData = new FormData();
      formData.append('imagen', groupImage);
      formData.append('nombre_imagen', 'Imagen grupo');
      //formData.append('estatus_sistema', true);
      const response = await postImage(formData);
      result = response.id;
      if (!response) return response;
    }
    const response = await api.put(`/app-personas/grupo/${groupId}`, {
      nombre_grupo: nameGroup, //this.group.nombre_grupo,
      sucursal: idBranch, //this.group.id_sucursal,
      salon_clases: idClassRoom, //this.group.id_salon_clases,
      imagen_grupo: result > 0 ? result : groupImage,
      ciclo_escolar: IdschoolCycle, //this.group.id_ciclo_escolar,
      duracion_ciclo_escolar_meses: duracion_ciclo_escolar_meses,
      institucion_educativa: institutionId, //this.group.id_institucion_educativa
      nivel_educativo: schoolLevelId ? schoolLevelId : null,
      plan_estudios: syllabusId ? syllabusId : null,
    });
    if (response.error) {
      console.error(response.error);
    } else {
      //await assignImageToGroup(this.group.id)
    }
  } catch (error) {
    console.error('Error al intentar actualizar el grupo', error);
  }
};
// Los deletes no eliminan los registros, cambian el estatus a False
export const deleteGroup = async (groupId) => {
  try {
    const response = await api.delete(`/app-personas/grupo/${groupId}`);
    if (response.error) {
      console.error(response.data);
    }
  } catch (error) {
    console.error('Error al intentar eliminar el grupo', error);
  }
};
// Sucursales

export const fetchBranchesByEI = async (institutionId) => {
  try {
    const response = await api.get(
      `/app-personas/filters/sucursal?institucion_educativa=${institutionId}&limit=100`
    );
    const responseData = await response.data;
    return responseData;
  } catch (error) {
    console.error(
      'Error al intentar obtener sucursales por institucion educativa',
      error
    );
  }
};
export const createBranch = async (
  numBranch,
  nameBranch,
  addressId,
  institutionId
) => {
  try {
    const response = await api.post('/app-personas/sucursal', {
      numero_sucursal: numBranch, //this.branch.numero_sucursal,
      nombre_sucursal: nameBranch, //this.branch.nombre_sucursal,
      direccion_sucursal: addressId, //this.branchAddress.id,
      institucion_educativa: institutionId, //this.institutionId
    });
    const responseData = await response.data;
    if (responseData.e) {
      console.error('Ocurrió un error, no se pudo crear la sucursal.');
    }
  } catch (error) {
    console.error('Error al intentar crear la sucursal', error);
    console.error('Error al intentar crear la sucursal');
  }
};
export const updateBranch = async (
  idBranch,
  numBranch,
  nameBranch,
  addressId,
  institutionId
) => {
  try {
    const response = await api.put(`/app-personas/sucursal/${idBranch}`, {
      numero_sucursal: numBranch, //this.branch.numero_sucursal,
      nombre_sucursal: nameBranch, //this.branch.nombre_sucursal,
      direccion_sucursal: addressId, //this.branchAddress.id,
      institucion_educativa: institutionId, //this.institutionId
    });
    const responseData = await response.data;
    if (responseData.e) {
      console.error('Ocurrió un error, no se pudo actualizar la sucursal');
    }
  } catch (error) {
    console.error('Error al intentar actualizar la sucursal', error);
  }
};
// Los deletes no eliminan los registros, cambian el estatus a False
export const deleteBranch = async (sucursalId) => {
  try {
    const response = await api.delete(`/app-personas/sucursal/${sucursalId}`);
    const responseData = await response.data;
    if (responseData.e) {
      console.error('Ocurrió un error, no se pudo eliminar la sucursal');
    }
  } catch (error) {
    console.error('Error al intentar eliminar la sucursal', error);
  }
};
export const createAddress = async (branchAddress) => {
  try {
    const response = await api.post('/app-personas/direccion', branchAddress); //this.branchAddress)
    const responseData = await response.data;
    // console.log("Respuesta direccion: ", responseData);
    if (responseData.e) {
      console.error(
        'No se pudo crear la dirección: ' + responseData.e.join(',')
      );
      return;
    }
    return responseData;
  } catch (error) {
    console.error('Error al intentar crear direccion');
    console.error('Error al intentar crear direccion.', error);
  }
};
export const updateAddress = async (idAdress, branchAddress) => {
  try {
    const response = await api.put(
      `/app-personas/direccion/${idAdress}`,
      branchAddress
    ); //this.branchAddress)
    const responseData = await response.data;
    // console.log("Respuesta actualizar direccion: ", responseData);
    if (responseData.e) {
      console.error(
        'No se pudo actualizar la dirección: ' + responseData.e.join(',')
      );
    }
  } catch (error) {
    console.error('Error al intentar actualizar direccion');
    console.error('Error al intentar actualizar direccion.', error);
  }
};
// Los deletes no eliminan los registros, cambian el estatus a False
export const deleteAddress = async (addressId) => {
  try {
    const response = await api.delete(`/app-personas/direccion/${addressId}`);
    const responseData = await response.data;
    if (responseData.e) {
      console.error('Ocurrió un error, no se pudo eliminar la dirección');
    }
  } catch (error) {
    console.error('Error al intentar eliminar la dirección', error);
  }
};

// Ciclo escolar
export const fetchSchoolCycles = async ({
  dato = null,
  institutionId = null,
  systemStatus = null,
  limit = null,
  offset = 0,
}) => {
  try {
    const institutionFilter = institutionId
      ? `institucion_educativa=${institutionId}`
      : '';
    const datoFilter = dato ? `&dato=${dato}` : '';
    const systemStatusFilter =
      systemStatus !== null ? `&estatus_sistema=${systemStatus}` : '';
    const limitFilter = limit !== null ? `&limit=${limit}` : '';
    const offsetFilter = offset !== 0 ? `&offset=${offset}` : '';
    const response = await getItem(
      '/app-personas/filters/ciclo-escolar?'.concat(
        institutionFilter,
        datoFilter,
        systemStatusFilter,
        limitFilter,
        offsetFilter
      )
    );
    const { results, count, e } = response;
    if (e) {
      return {
        ok: false,
        message: 'No se pudieron obtener los egresos por categoria. ' + e,
        count: 0,
      };
    }
    // console.log("Estatus", results);
    return { ok: true, data: results, count: count };
  } catch (error) {
    console.error('Error al obtener los egresos por categoria. ', error);
    return {
      ok: false,
      message: 'Error al intentar obtener los egresos por categoria. ' + error,
      count: 0,
    };
  }
};
/*export const fetchSchoolCycles = async (institutionId) => {
  try {
    const response = await api.get(
      `/app-personas/filters/ciclo-escolar?${institutionId}`
    );
    const responseData = await response.data;
    return responseData;
  } catch (error) {
    console.error("Error al intentar obtener ciclo escolar", error);
  }
};*/
export const createSchoolCycle = async (
  nameSchoolCicle,
  FirstDate,
  EndDate,
  institutionId
) => {
  try {
    const response = await api.post('/app-personas/ciclo-escolar', {
      dato: nameSchoolCicle, //this.schoolCycle.dato,
      fecha_inicio: FirstDate, //this.schoolCycle.fecha_inicio,
      fecha_fin: EndDate, //this.schoolCycle.fecha_fin,
      institucion_educativa: institutionId, //this.schoolCycle.id_institucion_educativa
    });
    const responseData = await response.data;
    if (responseData.e) {
      this.errors.push('Ocurrió un error, no se pudo crear el ciclo escolar.');
    }
  } catch (error) {
    console.error('Error al intentar crear el ciclo escolar', error);
    this.errors.push('Error al intentar crear el ciclo escolar');
  }
};
export const updateSchoolCycle = async (
  IdschoolCycle,
  nameSchoolCicle,
  FirstDate,
  EndDate,
  institutionId
) => {
  try {
    const response = await api.put(
      `/app-personas/ciclo-escolar/${IdschoolCycle}`,
      {
        dato: nameSchoolCicle, //this.schoolCycle.dato,
        fecha_inicio: FirstDate, //this.schoolCycle.fecha_inicio,
        fecha_fin: EndDate, //this.schoolCycle.fecha_fin,
        institucion_educativa: institutionId, //this.schoolCycle.id_institucion_educativa
      }
    );
    const responseData = await response.data;
    if (responseData.e) {
      this.errors.push(
        'Ocurrió un error, no se pudo actualizar el ciclo escolar'
      );
    }
  } catch (error) {
    console.error('Error al intentar actualizar el ciclo escolar', error);
  }
};
// Los deletes no eliminan los registros, cambian el estatus a False
export const deleteSchoolCycle = async (schoolCycleId) => {
  try {
    const response = await api.delete(
      `/app-personas/ciclo-escolar/${schoolCycleId}`
    );
    const responseData = await response.data;
    if (responseData.e) {
      this.errors.push(
        'Ocurrió un error, no se pudo eliminar el ciclo escolar'
      );
    }
  } catch (error) {
    console.error('Error al intentar eliminar el ciclo escolar', error);
  }
};

/// Salon de clases
export const fetchClassroom = async ({
  nombre_salon = null,
  ocupacion_maxima = null,
  sucursal = null,
  institutionId = null,
  systemStatus = null,
  limit = null,
  offset = 0,
}) => {
  try {
    const institutionFilter = institutionId
      ? `institucion_educativa=${institutionId}`
      : '';
    const nameClassRoomFilter = nombre_salon
      ? `&nombre_salon=${nombre_salon}`
      : '';
    const maximumOccupancyRoomFilter = ocupacion_maxima
      ? `&ocupacion_maxima=${ocupacion_maxima}`
      : '';
    const branchFilter = sucursal ? `&sucursal=${sucursal}` : '';
    const systemStatusFilter =
      systemStatus !== null ? `&estatus_sistema=${systemStatus}` : '';
    const limitFilter = limit !== null ? `&limit=${limit}` : '';
    const offsetFilter = offset !== 0 ? `&offset=${offset}` : '';
    const response = await getItem(
      '/app-personas/filters/salon-clases?'.concat(
        institutionFilter,
        nameClassRoomFilter,
        maximumOccupancyRoomFilter,
        branchFilter,
        systemStatusFilter,
        limitFilter,
        offsetFilter
      )
    );
    const { results, count, e } = response;
    if (e) {
      return {
        ok: false,
        message: 'No se pudieron obtener los egresos por categoria. ' + e,
        count: 0,
      };
    }
    // console.log("Estatus", results);
    return { ok: true, data: results, count: count };
  } catch (error) {
    console.error('Error al obtener los egresos por categoria. ', error);
    return {
      ok: false,
      message: 'Error al intentar obtener los egresos por categoria. ' + error,
      count: 0,
    };
  }
};
export const fetchSucursalsByEI = async (institutionId) => {
  try {
    const response = await api.get(
      `/app-personas/filters/sucursal?institucion_educativa=${institutionId}`
    );
    const responseData = await response.data;
    return responseData;
  } catch (error) {
    console.error(
      'Error al intentar obtener cuentas bancarias por institucion educativa',
      error
    );
  }
};
export const fetchClassroomByEI = async (institutionId) => {
  try {
    const response = await api.get(
      `/app-personas/filters/salon-clases?institucion_educativa=${institutionId}`
    );
    const responseData = await response.data;
    return responseData;
  } catch (error) {
    console.error(
      'Error al intentar obtener cuentas bancarias por institucion educativa',
      error
    );
  }
};
export const createClassroom = async (
  nameClassRoom,
  maximumOccupancy,
  branchName,
  idAutor,
  institutionId
) => {
  try {
    const response = await api.post('/app-personas/salon-clases ', {
      nombre_salon: nameClassRoom, //this.classroom.nombre_salon,
      ocupacion_maxima: maximumOccupancy, //this.classroom.ocupacion_maxima,
      sucursal: branchName, //this.classroom.id_sucursal,
      autor: idAutor, //this.classroom.id_autor,
      institucion_educativa: institutionId, //this.classroom.id_institucion_educativa,
    });
    const responseData = await response.data;
    if (responseData.e) {
      this.errors.push(
        'Ocurrió un error, no se pudo crear el salon de clases.'
      );
    }
  } catch (error) {
    console.error('Error al intentar crear el salon de clases', error);
    this.errors.push('Error al intentar crear el salon de clases');
  }
};
export const updateClassroom = async (
  idClassRoom,
  nameClassRoom,
  maximumOccupancy,
  branchName,
  idAutor,
  institutionId
) => {
  try {
    const response = await api.put(
      `/app-personas/salon-clases/${idClassRoom}`,
      {
        nombre_salon: nameClassRoom, //this.classroom.nombre_salon,
        ocupacion_maxima: maximumOccupancy, //this.classroom.ocupacion_maxima,
        sucursal: branchName, //this.classroom.id_sucursal,
        autor: idAutor, //this.classroom.id_autor,
        institucion_educativa: institutionId, //this.classroom.id_institucion_educativa,
      }
    );
    const responseData = await response.data;
    if (responseData.e) {
      this.errors.push(
        'Ocurrió un error, no se pudo actualizar el tipo de gasto'
      );
    }
  } catch (error) {
    console.error('Error al intentar actualizar el salon de clases', error);
  }
};
// Los deletes no eliminan los registros, cambian el estatus a False
export const deleteClassroom = async (classroomId) => {
  try {
    const response = await api.delete(
      `/app-personas/salon-clases/${classroomId}`
    );
    const responseData = await response.data;
    if (responseData.e) {
      this.errors.push(
        'Ocurrió un error, no se pudo eliminar el salon de clases'
      );
    }
  } catch (error) {
    console.error('Error al intentar eliminar el salon de clases', error);
  }
};
/** Periodo de evaluación */
export const fetchEvaluationPeriods = async ({
  dato = null,
  fecha_inicio = null,
  fecha_fin = null,
  institutionId = null,
  systemStatus = null,
  limit = null,
  offset = 0,
}) => {
  try {
    const institutionFilter = institutionId
      ? `institucion_educativa=${institutionId}`
      : '';
    const dateStartFilter = fecha_inicio ? `&fecha_inicio=${fecha_inicio}` : '';
    const dateEndFilter = fecha_fin ? `&fecha_fin=${fecha_fin}` : '';
    const nameFilter = dato ? `&dato=${dato}` : '';
    const systemStatusFilter =
      systemStatus !== null ? `&estatus_sistema=${systemStatus}` : '';
    const limitFilter = limit !== null ? `&limit=${limit}` : '';
    const offsetFilter = offset !== 0 ? `&offset=${offset}` : '';
    const response = await getItem(
      '/app-personas/filters/periodo-evaluacion?'.concat(
        institutionFilter,
        dateStartFilter,
        dateEndFilter,
        nameFilter,
        systemStatusFilter,
        limitFilter,
        offsetFilter
      )
    );
    const { results, count, e } = response;
    if (e) {
      return {
        ok: false,
        message: 'No se pudieron obtener los periodos de evaluación. ' + e,
        count: 0,
      };
    }
    // console.log("Estatus", results);
    return { ok: true, data: results, count: count };
  } catch (error) {
    console.error('Error al obtener los periodos de evaluación. ', error);
    return {
      ok: false,
      message: 'Error al intentar obtener los periodos de evaluación. ' + error,
      count: 0,
    };
  }
};
export const createEvaluationPeriod = async (
  dato,
  fecha_inicio,
  fecha_fin,
  institutionId
) => {
  try {
    const response = await api.post('/app-personas/periodo-evaluacion', {
      dato: dato, //this.classroom.nombre_salon,
      fecha_inicio: fecha_inicio, //this.classroom.ocupacion_maxima,
      fecha_fin: fecha_fin,
      institucion_educativa: institutionId, //this.classroom.id_institucion_educativa,
    });
    const responseData = await response.data;
    if (responseData.e) {
      this.errors.push(
        'Ocurrió un error, no se pudo crear el periodo de evaluación.'
      );
    }
  } catch (error) {
    console.error('Error al intentar crear el periodo de evaluación', error);
    this.errors.push('Error al intentar crear el periodo de evaluación');
  }
};
export const updateEvaluationPeriod = async (
  idEvaluationPeriod,
  dato,
  fecha_inicio,
  fecha_fin,
  institutionId
) => {
  try {
    const response = await api.put(
      `/app-personas/periodo-evaluacion/${idEvaluationPeriod}`,
      {
        dato: dato, //this.classroom.nombre_salon,
        fecha_inicio: fecha_inicio, //this.classroom.ocupacion_maxima,
        fecha_fin: fecha_fin,
        institucion_educativa: institutionId, //this.classroom.id_institucion_educativa,
      }
    );
    const responseData = await response.data;
    if (responseData.e) {
      this.errors.push(
        'Ocurrió un error, no se pudo actualizar el periodo de evaluación'
      );
    }
  } catch (error) {
    console.error(
      'Error al intentar actualizar el periodo de evaluación',
      error
    );
  }
};
// Los deletes no eliminan los registros, cambian el estatus a False
export const deleteEvaluationPeriod = async (idEvaluationPeriod) => {
  try {
    const response = await api.delete(
      `/app-personas/periodo-evaluacion/${idEvaluationPeriod}`
    );
    const responseData = await response.data;
    if (responseData.e) {
      this.errors.push(
        'Ocurrió un error, no se pudo eliminar el salon de clases'
      );
    }
  } catch (error) {
    console.error('Error al intentar eliminar el salon de clases', error);
  }
};
/** Estatus de calificación */
export const fetchQualificationStatus = async ({
  dato = null,
  systemStatus = null,
  limit = null,
  offset = 0,
}) => {
  try {
    const nameFilter = dato ? `&dato=${dato}` : '';
    const systemStatusFilter =
      systemStatus !== null ? `&estatus_sistema=${systemStatus}` : '';
    const limitFilter = limit !== null ? `&limit=${limit}` : '';
    const offsetFilter = offset !== 0 ? `&offset=${offset}` : '';
    const response = await getItem(
      '/app-personas/filters/estatus-calificacion?'.concat(
        nameFilter,
        systemStatusFilter,
        limitFilter,
        offsetFilter
      )
    );
    const { results, count, e } = response;
    if (e) {
      return {
        ok: false,
        message: 'No se pudieron obtener los estatus de calificaciones. ' + e,
        count: 0,
      };
    }
    // console.log("Estatus", results);
    return { ok: true, data: results, count: count };
  } catch (error) {
    console.error('Error al obtener los estatus de calificaciones. ', error);
    return {
      ok: false,
      message:
        'Error al intentar obtener los estatus de calificaciones. ' + error,
      count: 0,
    };
  }
};
export const createQualificationStatus = async (dato) => {
  try {
    const response = await api.post('/app-personas/estatus-calificacion', {
      dato: dato,
    });
    const responseData = await response.data;
    if (responseData.e) {
      this.errors.push(
        'Ocurrió un error, no se pudo crear estatus de calificación.'
      );
    }
  } catch (error) {
    console.error('Error al intentar crear estatus de calificación', error);
    this.errors.push('Error al intentar crear estatus de calificación');
  }
};
export const updateQualificationStatus = async (
  idQualificationStatus,
  dato
) => {
  try {
    const response = await api.put(
      `/app-personas/estatus-calificacion/${idQualificationStatus}`,
      {
        dato: dato,
      }
    );
    const responseData = await response.data;
    if (responseData.e) {
      this.errors.push(
        'Ocurrió un error, no se pudo actualizar los estatus de calificación'
      );
    }
  } catch (error) {
    console.error(
      'Error al intentar actualizar los estatus de calificación',
      error
    );
  }
};
// Los deletes no eliminan los registros, cambian el estatus a False
export const deleteQualificationStatus = async (idQualificationStatus) => {
  try {
    const response = await api.delete(
      `/app-personas/estatus-calificacion/${idQualificationStatus}`
    );
    const responseData = await response.data;
    if (responseData.e) {
      this.errors.push(
        'Ocurrió un error, no se pudo eliminar los estatus de calificación'
      );
    }
  } catch (error) {
    console.error(
      'Error al intentar eliminar los estatus de calificación',
      error
    );
  }
};
/** Area prepadéutica */
export const fetchPropaedeuticArea = async ({
  nombre = null,
  systemStatus = null,
  institutionId = null,
  limit = null,
  offset = 0,
}) => {
  try {
    const institutionFilter = institutionId
      ? `institucion_educativa=${institutionId}`
      : '';
    const nameFilter = nombre ? `&nombre=${nombre}` : '';
    const systemStatusFilter =
      systemStatus !== null ? `&estatus_sistema=${systemStatus}` : '';
    const limitFilter = limit !== null ? `&limit=${limit}` : '';
    const offsetFilter = offset !== 0 ? `&offset=${offset}` : '';
    const response = await getItem(
      '/app-personas/filters/area-propedeutica?'.concat(
        nameFilter,
        institutionFilter,
        systemStatusFilter,
        limitFilter,
        offsetFilter
      )
    );
    const { results, count, e } = response;
    if (e) {
      return {
        ok: false,
        message: 'No se pudieron obtener las areas prepadéuticas. ' + e,
        count: 0,
      };
    }
    // console.log("Estatus", results);
    return { ok: true, data: results, count: count };
  } catch (error) {
    console.error('Error al obtener las areas prepadéuticas. ', error);
    return {
      ok: false,
      message: 'Error al intentar obtener las areas prepadéuticas. ' + error,
      count: 0,
    };
  }
};
export const createPropaedeuticArea = async (
  nombre,
  descripcion,
  institucion_educativa
) => {
  try {
    const response = await api.post('/app-personas/area-propedeutica', {
      nombre: nombre, //this.classroom.nombre_salon,
      descripcion: descripcion, //this.classroom.ocupacion_maxima,
      institucion_educativa: institucion_educativa,
    });
    const responseData = await response.data;
    if (responseData.e) {
      this.errors.push('Ocurrió un error, no se pudo crear area prepadéutica.');
    }
  } catch (error) {
    console.error('Error al intentar crear area prepadéutica', error);
    this.errors.push('Error al intentar crear area prepadéutica');
  }
};
export const updatePropaedeuticArea = async (
  idPropaedeuticArea,
  nombre,
  descripcion,
  institucion_educativa
) => {
  try {
    const response = await api.put(
      `/app-personas/area-propedeutica/${idPropaedeuticArea}`,
      {
        nombre: nombre, //this.classroom.nombre_salon,
        descripcion: descripcion, //this.classroom.ocupacion_maxima,
        institucion_educativa: institucion_educativa,
      }
    );
    const responseData = await response.data;
    if (responseData.e) {
      this.errors.push(
        'Ocurrió un error, no se pudo actualizar area prepadéutica'
      );
    }
  } catch (error) {
    console.error('Error al intentar actualizar area prepadéutica', error);
  }
};
// Los deletes no eliminan los registros, cambian el estatus a False
export const deletePropaedeuticArea = async (idPropaedeuticArea) => {
  try {
    const response = await api.delete(
      `/app-personas/area-propedeutica/${idPropaedeuticArea}`
    );
    const responseData = await response.data;
    if (responseData.e) {
      this.errors.push(
        'Ocurrió un error, no se pudo eliminar area prepadéutica'
      );
    }
  } catch (error) {
    console.error('Error al intentar eliminar area prepadéutica', error);
  }
};

/** Nivel de desempeño */
export const fetchPerformanceLevel = async ({
  dato = null,
  systemStatus = null,
  //institutionId = null,
  limit = null,
  offset = 0,
}) => {
  try {
    /*const institutionFilter = institutionId
      ? `institucion_educativa=${institutionId}`
      : '';*/
    const nameFilter = dato ? `&dato=${dato}` : '';
    const systemStatusFilter =
      systemStatus !== null ? `&estatus_sistema=${systemStatus}` : '';
    const limitFilter = limit !== null ? `&limit=${limit}` : '';
    const offsetFilter = offset !== 0 ? `&offset=${offset}` : '';
    const response = await getItem(
      '/app-personas/filters/nivel-desempenio?'.concat(
        nameFilter,
        //institutionFilter,
        systemStatusFilter,
        limitFilter,
        offsetFilter
      )
    );
    const { results, count, e } = response;
    if (e) {
      return {
        ok: false,
        message: 'No se pudieron obtener los niveles de desempeño. ' + e,
        count: 0,
      };
    }
    // console.log("Estatus", results);
    return { ok: true, data: results, count: count };
  } catch (error) {
    console.error('Error al obtener los niveles de desempeño. ', error);
    return {
      ok: false,
      message: 'Error al intentar obtener los niveles de desempeño. ' + error,
      count: 0,
    };
  }
};
export const createPerformanceLevel = async (dato) => {
  try {
    const response = await api.post('/app-personas/nivel-desempenio', {
      dato: dato,
    });
    const responseData = await response.data;
    if (responseData.e) {
      this.errors.push(
        'Ocurrió un error, no se pudo crear el nivel de desempeño.'
      );
    }
  } catch (error) {
    console.error('Error al intentar crear el nivel de desempeño', error);
    this.errors.push('Error al intentar crear el nivel de desempeño');
  }
};
export const updatePerformanceLevel = async (idPerformanceLevel, dato) => {
  try {
    const response = await api.put(
      `/app-personas/nivel-desempenio/${idPerformanceLevel}`,
      {
        dato: dato,
      }
    );
    const responseData = await response.data;
    if (responseData.e) {
      this.errors.push(
        'Ocurrió un error, no se pudo actualizar el nivel de desempeño'
      );
    }
  } catch (error) {
    console.error('Error al intentar actualizar el nivel de desempeño', error);
  }
};
// Los deletes no eliminan los registros, cambian el estatus a False
export const deletePerformanceLevel = async (idPerformanceLevel) => {
  try {
    const response = await api.delete(
      `/app-personas/nivel-desempenio/${idPerformanceLevel}`
    );
    const responseData = await response.data;
    if (responseData.e) {
      this.errors.push(
        'Ocurrió un error, no se pudo eliminar el nivel de desempeño'
      );
    }
  } catch (error) {
    console.error('Error al intentar eliminar el nivel de desempeño', error);
  }
};
/** Campo formativo */
export const fetchTrainingField = async ({
  dato = null,
  systemStatus = null,
  institutionId = null,
  limit = null,
  offset = 0,
}) => {
  try {
    const institutionFilter = institutionId
      ? `institucion_educativa=${institutionId}`
      : '';
    const nameFilter = dato ? `&dato=${dato}` : '';
    const systemStatusFilter =
      systemStatus !== null ? `&estatus_sistema=${systemStatus}` : '';
    const limitFilter = limit !== null ? `&limit=${limit}` : '';
    const offsetFilter = offset !== 0 ? `&offset=${offset}` : '';
    const response = await getItem(
      '/app-personas/filters/campo-formativo?'.concat(
        nameFilter,
        institutionFilter,
        systemStatusFilter,
        limitFilter,
        offsetFilter
      )
    );
    const { results, count, e } = response;
    if (e) {
      return {
        ok: false,
        message: 'No se pudieron obtener los campos formativos. ' + e,
        count: 0,
      };
    }
    // console.log("Estatus", results);
    return { ok: true, data: results, count: count };
  } catch (error) {
    console.error('Error al obtener los campos formativos. ', error);
    return {
      ok: false,
      message: 'Error al intentar obtener los campos formativos. ' + error,
      count: 0,
    };
  }
};
export const createTrainingField = async (
  nombre,
  descripcion,
  institutionId
) => {
  try {
    const response = await api.post('/app-personas/campo-formativo', {
      nombre: nombre,
      descripcion: descripcion,
      institucion_educativa: institutionId,
    });
    const responseData = await response.data;
    if (responseData.e) {
      this.errors.push(
        'Ocurrió un error, no se pudo crear los campos formativos.'
      );
    }
  } catch (error) {
    console.error('Error al intentar crear los campos formativos', error);
    this.errors.push('Error al intentar crear los campos formativos');
  }
};
export const updateTrainingField = async (
  idTrainingField,
  nombre,
  descripcion,
  institutionId
) => {
  try {
    const response = await api.put(
      `/app-personas/campo-formativo/${idTrainingField}`,
      {
        nombre,
        descripcion,
        institutionId,
      }
    );
    const responseData = await response.data;
    if (responseData.e) {
      this.errors.push(
        'Ocurrió un error, no se pudo actualizar los campos formativos'
      );
    }
  } catch (error) {
    console.error('Error al intentar actualizar los campos formativos', error);
  }
};
// Los deletes no eliminan los registros, cambian el estatus a False
export const deleteTrainingField = async (idTrainingField) => {
  try {
    const response = await api.delete(
      `/app-personas/campo-formativo/${idTrainingField}`
    );
    const responseData = await response.data;
    if (responseData.e) {
      this.errors.push(
        'Ocurrió un error, no se pudo eliminar los campos formativos'
      );
    }
  } catch (error) {
    console.error('Error al intentar eliminar los campos formativos', error);
  }
};

/** Actividad extra escolar */
export const fetchExtracurricularActivity = async ({
  nombre = null,
  fecha_inicio = null,
  fecha_fin = null,
  systemStatus = null,
  institutionId = null,
  limit = null,
  offset = 0,
}) => {
  try {
    const institutionFilter = institutionId
      ? `institucion_educativa=${institutionId}`
      : '';
    const nameFilter = nombre ? `&nombre=${nombre}` : '';
    const startDateFilter = fecha_inicio ? `&fecha_inicio=${fecha_inicio}` : '';
    const endDateFilter = fecha_fin ? `&fecha_fin=${fecha_fin}` : '';
    const systemStatusFilter =
      systemStatus !== null ? `&estatus_sistema=${systemStatus}` : '';
    const limitFilter = limit !== null ? `&limit=${limit}` : '';
    const offsetFilter = offset !== 0 ? `&offset=${offset}` : '';
    const response = await getItem(
      '/app-personas/filters/actividad-extracurricular?'.concat(
        nameFilter,
        startDateFilter,
        endDateFilter,
        institutionFilter,
        systemStatusFilter,
        limitFilter,
        offsetFilter
      )
    );
    const { results, count, e } = response;
    if (e) {
      return {
        ok: false,
        message: 'No se pudieron obtener las actividades extraescolares. ' + e,
        count: 0,
      };
    }
    // console.log("Estatus", results);
    return { ok: true, data: results, count: count };
  } catch (error) {
    console.error('Error al obtener las actividades extraescolares. ', error);
    return {
      ok: false,
      message:
        'Error al intentar obtener las actividades extraescolares. ' + error,
      count: 0,
    };
  }
};
export const createExtracurricularActivity = async (
  nombre,
  descripcion,
  fecha_inicio,
  fecha_fin,
  institutionId
) => {
  try {
    const response = await api.post('/app-personas/actividad-extracurricular', {
      nombre: nombre,
      descripcion: descripcion,
      fecha_inicio: fecha_inicio,
      fecha_fin: fecha_fin,
      institucion_educativa: institutionId,
    });
    const responseData = await response.data;
    if (responseData.e) {
      this.errors.push(
        'Ocurrió un error, no se pudo crear las actividades extra escolares.'
      );
    }
  } catch (error) {
    console.error(
      'Error al intentar crear las actividades extra escolares',
      error
    );
    this.errors.push('Error al intentar crear las actividades extra escolares');
  }
};
export const updateExtracurricularActivity = async (
  idExtracurricularActivity,
  nombre,
  descripcion,
  fecha_inicio,
  fecha_fin,
  institutionId
) => {
  try {
    const response = await api.put(
      `/app-personas/actividad-extracurricular/${idExtracurricularActivity}`,
      {
        nombre: nombre,
        descripcion: descripcion,
        fecha_inicio: fecha_inicio,
        fecha_fin: fecha_fin,
        institucion_educativa: institutionId,
      }
    );
    const responseData = await response.data;
    if (responseData.e) {
      this.errors.push(
        'Ocurrió un error, no se pudo actualizar las actividades extraescolares'
      );
    }
  } catch (error) {
    console.error(
      'Error al intentar actualizar las actividades extraescolares',
      error
    );
  }
};
// Los deletes no eliminan los registros, cambian el estatus a False
export const deleteExtracurricularActivity = async (
  idExtracurricularActivity
) => {
  try {
    const response = await api.delete(
      `/app-personas/actividad-extracurricular/${idExtracurricularActivity}`
    );
    const responseData = await response.data;
    if (responseData.e) {
      this.errors.push(
        'Ocurrió un error, no se pudo eliminar las actividades extraescolares'
      );
    }
  } catch (error) {
    console.error(
      'Error al intentar eliminar las actividades extraescolares',
      error
    );
  }
};
/** Criterios de evaluación */
export const fetchEvaluationCriteria = async ({
  nombre = null,
  systemStatus = null,
  limit = null,
  offset = 0,
}) => {
  try {
    /*const institutionFilter = institutionId
      ? `institucion_educativa=${institutionId}`
      : '';*/
    const nameFilter = nombre ? `&nombre=${nombre}` : '';
    const systemStatusFilter =
      systemStatus !== null ? `&estatus_sistema=${systemStatus}` : '';
    const limitFilter = limit !== null ? `&limit=${limit}` : '';
    const offsetFilter = offset !== 0 ? `&offset=${offset}` : '';
    const response = await getItem(
      '/app-personas/filters/criterio-evaluacion?'.concat(
        nameFilter,
        //institutionFilter,
        systemStatusFilter,
        limitFilter,
        offsetFilter
      )
    );
    const { results, count, e } = response;
    if (e) {
      return {
        ok: false,
        message: 'No se pudieron obtener los criterios de evaluación. ' + e,
        count: 0,
      };
    }
    // console.log("Estatus", results);
    return { ok: true, data: results, count: count };
  } catch (error) {
    console.error('Error al obtener los criterios de evaluación. ', error);
    return {
      ok: false,
      message:
        'Error al intentar obtener los criterios de evaluación. ' + error,
      count: 0,
    };
  }
};
export const createEvaluationCriteria = async (
  nombre,
  descripcion,
  porcentaje
) => {
  try {
    const response = await api.post('/app-personas/criterio-evaluacion', {
      nombre: nombre,
      descripcion: descripcion,
      porcentaje: porcentaje,
    });
    const responseData = await response.data;
    if (responseData.e) {
      this.errors.push(
        'Ocurrió un error, no se pudo crear los criterios de evaluación.'
      );
    }
  } catch (error) {
    console.error('Error al intentar crear los criterios de evaluación', error);
    this.errors.push('Error al intentar crear los criterios de evaluación');
  }
};
export const updateEvaluationCriteria = async (
  idEvaluationCriteria,
  nombre,
  descripcion,
  porcentaje
) => {
  try {
    const response = await api.put(
      `/app-personas/criterio-evaluacion/${idEvaluationCriteria}`,
      {
        nombre: nombre,
        descripcion: descripcion,
        porcentaje: porcentaje,
      }
    );
    const responseData = await response.data;
    if (responseData.e) {
      this.errors.push(
        'Ocurrió un error, no se pudo actualizar los criterios de evaluación'
      );
    }
  } catch (error) {
    console.error(
      'Error al intentar actualizar los criterios de evaluación',
      error
    );
  }
};
// Los deletes no eliminan los registros, cambian el estatus a False
export const deleteEvaluationCriteria = async (idEvaluationCriteria) => {
  try {
    const response = await api.delete(
      `/app-personas/criterio-evaluacion/${idEvaluationCriteria}`
    );
    const responseData = await response.data;
    if (responseData.e) {
      this.errors.push(
        'Ocurrió un error, no se pudo eliminar los criterios de evaluación'
      );
    }
  } catch (error) {
    console.error(
      'Error al intentar eliminar los criterios de evaluación',
      error
    );
  }
};
/** Nivel educativo */
export const fetchEducationalLevel = async ({
  nombre = null,
  institutionId = null,
  systemStatus = null,
  limit = null,
  offset = 0,
}) => {
  try {
    const institutionFilter = institutionId
      ? `institucion_educativa=${institutionId}`
      : '';
    const nameFilter = nombre ? `&nombre=${nombre}` : '';
    const systemStatusFilter =
      systemStatus !== null ? `&estatus_sistema=${systemStatus}` : '';
    const limitFilter = limit !== null ? `&limit=${limit}` : '';
    const offsetFilter = offset !== 0 ? `&offset=${offset}` : '';
    const response = await getItem(
      '/app-personas/filters/nivel-educativo?'.concat(
        nameFilter,
        institutionFilter,
        systemStatusFilter,
        limitFilter,
        offsetFilter
      )
    );
    const { results, count, e } = response;
    if (e) {
      return {
        ok: false,
        message: 'No se pudieron obtener los niveles educativos. ' + e,
        count: 0,
      };
    }
    // console.log("Estatus", results);
    return { ok: true, data: results, count: count };
  } catch (error) {
    console.error('Error al obtener los niveles educativos. ', error);
    return {
      ok: false,
      message: 'Error al intentar obtener los niveles educativos. ' + error,
      count: 0,
    };
  }
};
export const createEducationalLevel = async (
  nombre,
  descripcion,
  rvoe,
  institucion_educativa
) => {
  try {
    const response = await api.post('/app-personas/nivel-educativo', {
      nombre: nombre,
      descripcion: descripcion,
      rvoe: rvoe,
      institucion_educativa: institucion_educativa,
    });
    const responseData = await response.data;
    if (responseData.e) {
      this.errors.push(
        'Ocurrió un error, no se pudo crear los niveles educativos.'
      );
    }
  } catch (error) {
    console.error('Error al intentar crear los niveles educativos', error);
    this.errors.push('Error al intentar crear los niveles educativos');
  }
};
export const updateEducationalLevel = async (
  idEducationalLevel,
  nombre,
  descripcion,
  rvoe,
  institucion_educativa
) => {
  try {
    const response = await api.put(
      `/app-personas/nivel-educativo/${idEducationalLevel}`,
      {
        nombre: nombre,
        descripcion: descripcion,
        rvoe: rvoe,
        institucion_educativa: institucion_educativa,
      }
    );
    const responseData = await response.data;
    if (responseData.e) {
      this.errors.push(
        'Ocurrió un error, no se pudo actualizar los niveles educativos'
      );
    }
  } catch (error) {
    console.error('Error al intentar actualizar los niveles educativos', error);
  }
};
// Los deletes no eliminan los registros, cambian el estatus a False
export const deleteEducationalLevel = async (idEducationalLevel) => {
  try {
    const response = await api.delete(
      `/app-personas/nivel-educativo/${idEducationalLevel}`
    );
    const responseData = await response.data;
    if (responseData.e) {
      this.errors.push(
        'Ocurrió un error, no se pudo eliminar los niveles educativos'
      );
    }
  } catch (error) {
    console.error('Error al intentar eliminar los niveles educativos', error);
  }
};
//Materias
export const fetchSubjects = async ({
  nombre = null,
  plan_estudios = null,
  area_propedeutica = null,
  campos_formativos = null,
  criterios_evaluacion = null,
  institutionId = null,
  extracurricular = null,
  systemStatus = null,
  limit = null,
  offset = 0,
}) => {
  try {
    const institutionFilter = institutionId
      ? `institucion_educativa=${institutionId}`
      : '';
    const nameFilter = nombre !== null ? `&nombre=${nombre}` : '';
    const planEstudios =
      plan_estudios !== null ? `&plan_estudios=${plan_estudios}` : '';
    const areaPropedeutica =
      area_propedeutica !== null
        ? `&area_propedeutica=${area_propedeutica}`
        : '';
    const camposFormativos =
      campos_formativos !== null
        ? `&campos_formativos=${campos_formativos}`
        : '';
    const criteriosEvaluacion =
      criterios_evaluacion !== null
        ? `&criterios_evaluacion=${criterios_evaluacion}`
        : '';
    const extracurricularFilter =
      extracurricular !== null ? `&extracurricular=${extracurricular}` : '';
    const estatusSistema =
      systemStatus !== null ? `&estatus_sistema=${systemStatus}` : '';
    const limitFilter = limit !== null ? `&limit=${limit}` : '';
    const offsetFilter = offset !== 0 ? `&offset=${offset}` : '';
    const response = await getItem(
      '/app-personas/filters/materia?'.concat(
        nameFilter,
        institutionFilter,
        planEstudios,
        areaPropedeutica,
        camposFormativos,
        criteriosEvaluacion,
        extracurricularFilter,
        estatusSistema,
        limitFilter,
        offsetFilter
      )
    );
    const { results, count, e } = response;
    if (e) {
      return {
        ok: false,
        message: 'No se pudieron obtener las materias. ' + e,
        count: 0,
      };
    }
    // console.log("Estatus", results);
    return { ok: true, data: results, count: count };
  } catch (error) {
    console.error('Error al obtener las materias. ', error);
    return {
      ok: false,
      message: 'Error al intentar obtener las materias. ' + error,
      count: 0,
    };
  }
};

//Calificaciones
export const fetchQualifications = async ({
  boleta = null,
  materia_alumno = null,
  periodo = null,
  mes = null,
  calificacion = null,
  estatus_calificacion = null,
  systemStatus = null,
  limit = null,
  offset = 0,
}) => {
  try {
    const ballotFilter = boleta !== null ? `boleta=${boleta}` : '';
    const studentSubjectFilter =
      materia_alumno !== null ? `&materia_alumno=${materia_alumno}` : '';
    const period = periodo !== null ? `&periodo=${periodo}` : '';
    const monthFilter = mes !== null ? `&mes=${mes}` : '';
    const qualificationsFilter =
      calificacion !== null ? `&calificacion=${calificacion}` : '';
    const qualificationsStatus =
      estatus_calificacion !== null
        ? `estatus_calificacion=${estatus_calificacion}`
        : '';
    const estatusSistema =
      systemStatus !== null ? `&estatus_sistema=${systemStatus}` : '';
    const limitFilter = limit !== null ? `&limit=${limit}` : '';
    const offsetFilter = offset !== 0 ? `&offset=${offset}` : '';
    const response = await getItem(
      '/app-personas/filters/calificacion?'.concat(
        ballotFilter,
        studentSubjectFilter,
        period,
        monthFilter,
        qualificationsFilter,
        qualificationsStatus,
        estatusSistema,
        limitFilter,
        offsetFilter
      )
    );
    const { results, count, e } = response;
    if (e) {
      return {
        ok: false,
        message: 'No se pudieron obtener las calificaciones. ' + e,
        count: 0,
      };
    }
    // console.log("Estatus", results);
    return { ok: true, data: results, count: count };
  } catch (error) {
    console.error('Error al obtener las materias. ', error);
    return {
      ok: false,
      message: 'Error al intentar obtener las materias. ' + error,
      count: 0,
    };
  }
};
export const getStudentById = async (idsubject) => {
  try {
    // Fetch the criteria data
    const response = await getItem(`/app-personas/alumno/${idsubject}`);

    // Return the value directly from the resolved promise
    return response || 'Sin alumnos';
  } catch (error) {
    // Log the error for debugging purposes
    console.error('Error al intentar obtener los alumnos:', error);

    // Return a default message in case of an error
    return 'Sin alumnos';
  }
};